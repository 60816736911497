/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItemWithSubMain} from './AsideMenuItemWithSubMain'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()
  return (
    <>
      <AsideMenuItem
        to='/crafted/pages/profile/overview'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
              fontIcon='bi-person'
        bsTitle={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        className='py-3'
      />
      <AsideMenuItem
              to='/crafted/pages/wizards/vertical'
              title='Ετήσια Δήλωση Στοιχείων'
              bsTitle='Ετήσια Δήλωση Στοιχείων'
        fontIcon='bi-pen'
        className='py-3'
          />
          <AsideMenuItem
              to='crafted/payment/'
              title='Πληρωμή'
              bsTitle='Πληρωμή'
              fontIcon='bi-credit-card'
              className='py-3'
          />
          <AsideMenuItem
              to='/apps/user-management/'
              title='Λίστα Πιστοποιητικών'
              bsTitle='Λίστα Πιστοποιητικών'
              fontIcon='bi-card-list'
              className='py-3'
          />
          <AsideMenuItem
              to='/crafted/files/'
              title='Λίστα Αρχείων'
              bsTitle='Λίστα Αρχείων'
              fontIcon='bi-card-text'
              className='py-3'
          />
          <AsideMenuItem
              to='/crafted/deletion/'
              title='Αίτηση για Διαγραφή'
              bsTitle='Αίτηση για Διαγραφή'
              fontIcon='bi-card-text'
              className='py-3'
          />
     

      
    </>
  )
}
