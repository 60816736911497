import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../../../../../_metronic/helpers'
import { User, UsersQueryResponse, Certificate, CertificateResponse } from './_models'


const API_URL = 'https://e-services.isli.gr'
const Certificate_URL = `${API_URL}/Certificate`
const GET_CertificateS_URL = `${API_URL}/Certificate/`
const API_URL1 = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL1}/user`
const GET_USERS_URL = `${API_URL1}/users/query`


const getCertificates = (query: string): Promise<CertificateResponse> => {
    console.log("query")
    console.log(query)
    console.log('Fetched data:', axios
        .get(`${GET_USERS_URL}`)
        .then((d: AxiosResponse<UsersQueryResponse>) => d.data));
    console.log('Fetched data cer:', axios
        .get(`${GET_CertificateS_URL}`)
        .then((d: AxiosResponse<UsersQueryResponse>) => d.data));
  return axios
      .get(`${GET_CertificateS_URL}checkcertificate?${query}`)
      .then((d: AxiosResponse<CertificateResponse>) => d.data)
}

//const getCertificates = (query: string): Promise<CertificateResponse> => {
//}


const getCertificateById = (id: ID): Promise<Certificate | undefined> => {
  return axios
    .get(`${Certificate_URL}/${id}`)
      .then((response: AxiosResponse<Response<Certificate>>) => response.data)
      .then((response: Response<Certificate>) => response.data)
}

const createCertificate = (Certificate: Certificate): Promise<Certificate | undefined> => {
    console.log('createCertificate');
    console.log(Certificate);
    Certificate.CertifId = 0;
    return axios
        .post(`${Certificate_URL}/create`, Certificate)
      .then((response: AxiosResponse<Response<Certificate>>) => response.data)
      .then((response: Response<Certificate>) => response.data)
}
const getCertificateList = (Certificate: Certificate): Promise<Certificate | undefined> => {
    console.log('createCertificate');
    console.log(Certificate);
    Certificate.CertifId = 0;
    return axios
        .post(`${Certificate_URL}/create`, Certificate)
        .then((response: AxiosResponse<Response<Certificate>>) => response.data)
        .then((response: Response<Certificate>) => response.data)
}

const updateCertificate = (Certificate: Certificate): Promise<Certificate | undefined> => {
  return axios
    .post(`${Certificate_URL}/${Certificate.CertifId}`, Certificate)
      .then((response: AxiosResponse<Response<Certificate>>) => response.data)
      .then((response: Response<Certificate>) => response.data)
}

const deleteCertificate = (CertificateId: ID): Promise<void> => {
  return axios.delete(`${Certificate_URL}/${CertificateId}`).then(() => {})
}

const deleteSelectedCertificates = (CertificateIds: Array<ID>): Promise<void> => {
  const requests = CertificateIds.map((id) => axios.delete(`${Certificate_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getCertificates, deleteCertificate, deleteSelectedCertificates, getCertificateById, createCertificate, updateCertificate}
