/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { ID, KTIcon, QUERIES } from '../../../../../../../_metronic/helpers'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { deleteUser } from '../../core/_requests'

type Props = {
    certifId: number
}

const UserActionsCell: FC<Props> = ({ certifId }) => {
    const { setItemIdForUpdate, setPreview, } = useListView()
    const { query } = useQueryResponse()
    const queryClient = useQueryClient()
   
    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

  
    const deleteItem = useMutation(() => deleteUser(certifId), {
        // 💡 response of the mutation is passed to onSuccess
        onSuccess: () => {
            // ✅ update detail view directly
            queryClient.invalidateQueries([`${QUERIES.USERS_LIST}-${query}`])
        },
    })
    const openPreviewModal = () => {
        setItemIdForUpdate(certifId);
        setPreview(true)
    }
    const downloadFile = async () => {
        const fileName = 'certificate.pdf'; // Replace with the actual file name
        const response = await fetch(`https://e-services.isli.gr/Certificate/download/${certifId}`);
        if (response.ok) {

            const textData = await response.text();
            const byteCharacters = atob(textData);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/octet-stream' });

            // Create a download link
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;

            // Simulate a click on the link to trigger the download
            link.click();

            // Clean up
            URL.revokeObjectURL(link.href);
        } else {
            console.error('Failed to fetch file:', response.statusText);
        }
        // Create a Blob from the base64 string

    };
    return (
        <>
            <a
                href='#'
                className='btn btn-light btn-active-light-primary btn-sm'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
            >
                Ενέργειες
                <KTIcon iconName='down' className='fs-5 m-0' />
            </a>
            {/* begin::Menu */}
            <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4'
                data-kt-menu='true'
            >
                {/* begin::Menu item */}
               
                {/* end::Menu item */}
                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                    <a className='menu-link px-3' onClick={openPreviewModal}>
                        Επισκόπηση
                    </a>
                </div>
                {/* end::Menu item */}

                {/* begin::Menu item */}
                <div className='menu-item px-3'>
                    <a className='menu-link px-3' onClick={downloadFile} >
                        Κατέβασμα
                    </a>
                </div>
                {/* end::Menu item */}
            </div>
            {/* end::Menu */}
        </>
    )
}

export { UserActionsCell }
