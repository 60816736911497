import {ListViewProvider, useListView} from './core/ListViewProvider'
import { QueryRequestProvider, useQueryRequest} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import { UserPreviewModal } from './user-preview-modal/UserPreviewModal'
import {UsersTable} from './table/UsersTable'
import {UserEditModal} from './user-edit-modal/UserEditModal'
import { KTCard, KTIcon, useDebounce, initialQueryState} from '../../../../../_metronic/helpers'
import React, { useState, useEffect } from 'react';

const UsersList = () => {
    const { updateState } = useQueryRequest()

    const { itemIdForUpdate, preview } = useListView()
    const [certificateNumber, setCertificateNumber] = useState('');
    const [searchTerm, setSearchTerm] = useState<string>('')
    // Debounce search term so that it only gives us latest value ...
    // ... if searchTerm has not been updated within last 500ms.
    // The goal is to only have the API call fire when user stops typing ...
    // ... so that we aren't hitting our API rapidly.
    const debouncedSearchTerm = useDebounce(searchTerm, 150)
    // Effect for API call
    useEffect(
        () => {
            if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
                updateState({ search: debouncedSearchTerm, ...initialQueryState })
            }
        },
        [debouncedSearchTerm] // Only call effect if debounced search term changes
        // More details about useDebounce: https://usehooks.com/useDebounce/
    )
    const handleSubmit = (e) => {
        e.preventDefault();
        
        console.log("submit")
        setSearchTerm(certificateNumber)
        console.log(debouncedSearchTerm)
        console.log(searchTerm)
        if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
            console.log("Update")
            updateState({ search: debouncedSearchTerm, ...initialQueryState })
        }
        // Implement your submit logic here, e.g., make an API call
        console.log('Certificate Number submitted:', certificateNumber);
    };
  return (
    <>
      <KTCard>
              <div className='card-header border-0 pt-6'>
                  <div className='card-title align-items-center justify-content-center col-12' >
                      <form onSubmit={(e) => handleSubmit(e)}>
                      {/* begin::Search */}
                      <div className='d-flex align-items-center position-relative my-1 '>
                          <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                          <input
                                  type='text'
                                  value={certificateNumber }
                                  onChange={(e) => setCertificateNumber(e.target.value)}
                              className='form-control form-control-solid w-250px ps-14'
                              placeholder='Αριθμός Πιστοποιητικού'
                              />
                              <button type='submit' className='btn btn-primary' >
                              Υποβολή

                          </button>

                          </div>
                      </form>
                      {/* end::Search */}
                  </div>


                  {/* end::Card toolbar */}
              </div>
              {searchTerm && <UsersTable />}
      </KTCard>
          {itemIdForUpdate !== undefined && !preview && <UserEditModal />}
          {preview && itemIdForUpdate !== undefined && <UserPreviewModal id={itemIdForUpdate} />}
    </>
  )
}

const UsersListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <UsersList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {UsersListWrapper}
