import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { UsersListWrapper } from './certificatecheck/UsersList'

const usersBreadcrumbs: Array<PageLink> = [
    
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

const CheckCertificate = () => {
    return (
        <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>Έλεγχος Πιστοποιητικών</PageTitle>
            <div className='col-12 my-10 text-center'>
                <h2 className='fw-bolder text-dark text-align-center'>Έλεγχος Γνησιότητας Πιστοποιητικού </h2>
            </div>
            <UsersListWrapper />
        </>
    )
}

export { CheckCertificate }
