import { useQuery } from 'react-query';
import { useState } from 'react';
import { UserEditModalForm } from './UserEditModalForm';
import { isNotEmpty, QUERIES } from '../../../../../../_metronic/helpers';
import { useListView } from '../core/ListViewProvider';
import { getCertificateById } from '../core/_requestsCertificate';
import { initialUser, User, Certificate } from '../core/_models';

const UserEditModalFormWrapper = () => {
    const { itemIdForUpdate, setItemIdForUpdate } = useListView();
    const enabledQuery: boolean = isNotEmpty(itemIdForUpdate);
    const {
        isLoading,
        data: certificate,
        error,
    } = useQuery(
        `${QUERIES.USERS_LIST}-user-${itemIdForUpdate}`,
        () => getCertificateById(itemIdForUpdate),
        {
            cacheTime: 0,
            enabled: enabledQuery,
            onError: (err) => {
                setItemIdForUpdate(undefined);
                console.error(err);
            },
        }
    );

    // Move the useState declaration here, after fetching the certificate
    const [userForEdit, setUserForEdit] = useState<Certificate>({
        ...(certificate ?? {}),
        CertifId: (certificate?.CertifId ?? 0) as number,
        AppId: (certificate?.AppId ?? 0) as number,
        ParagrCode: certificate?.ParagrCode ?? '',
        PrintDate: certificate?.PrintDate ?? null,
        Notes: certificate?.Notes ?? null,
        Cancel: certificate?.Cancel ?? null,
        ToIs: certificate?.ToIs ?? null,
        Id: certificate?.Id ?? null,
        PdfName: certificate?.PdfName ?? null,
        CertCode: certificate?.CertCode ?? null,
        CertData: certificate?.CertData ?? null,
        Issuer: (certificate?.Issuer ?? 0) as number,
        CancelPrinted: certificate?.CancelPrinted ?? null,
        AA: certificate?.AA ?? null,
        DiaygeiaCode: certificate?.DiaygeiaCode ?? null,
        DeciplineNote: certificate?.DeciplineNote ?? null,
        ExactCopy: certificate?.ExactCopy ?? null,
    });

    if (!itemIdForUpdate) {
        return <UserEditModalForm isUserLoading={isLoading} certificate={userForEdit} />;
    }

    if (!isLoading && !error && certificate) {
        return <UserEditModalForm isUserLoading={isLoading} certificate={certificate} />;
    }

    return null;
};

export { UserEditModalFormWrapper };