import React from 'react'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useIntl} from 'react-intl'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({ id: 'MENU.DASHBOARD' })} to='/crafted/pages/profile/overview' />
          <MenuItem title='Ετήσια Δήλωση Στοιχείων' to='/crafted/pages/wizards/vertical' />
          <MenuItem title='Πληρωμή' to='crafted/payment/' />

          <MenuItem
        title='Λίστα Πιστοποιητικών'
        to='/apps/user-management/'
      />
          <MenuItem
              title='Λίστα Αρχείων'
              to='/crafted/files/'
          />
          <MenuItem
              title='Αίτηση για Διαγραφή'
              to='/crafted/deletion/'
          />
     
    </>
  )
}
