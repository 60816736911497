import axios from 'axios'
import { AuthModel, UserModel } from './_models'

const API_URL = "https://e-services.isli.gr/Auth"

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgotpassword`
export const RESET_PASSWORD_URL = `${API_URL}/resetpassword` // Assuming this endpoint exists

// Server should return AuthModel
export function login(email: string, password: string) {
    console.log(API_URL);
    return axios.post<AuthModel>(LOGIN_URL, {
        email,
        password,
    })
}

// Server should return AuthModel
export function register(
    email: string,
    firstname: string,
    lastname: string,
    password: string,
    confirmPassword: string,
    year: string,
    am: string

) {
    return axios.post(REGISTER_URL, {
        email,
        firstname,
        lastname,
        password,
        confirmPassword,
        YearOfBirth: year,
        am

    })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
    return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
        email,
    })
}

// Function to reset password with a code, new password, and email
export function resetPassword(email: string, password: string, confirmpassword: string, code: string | undefined) {
    return axios.post<{ result: boolean }>(RESET_PASSWORD_URL, {
        email,
        password,
        confirmpassword,
        code, // The code received from the URL or email
    })
}

// Server should return UserModel based on the token
export function getUserByToken(token: string) {
    console.log(token)
    console.log("getUserByToken")
    return axios.get<UserModel>(`${GET_USER_BY_ACCESSTOKEN_URL}/${token}`,
    )
}
