import axios, {AxiosResponse} from 'axios'
import { type } from 'os'
import { ID, Response } from '../../_metronic/helpers'


const API_URL = 'https://e-services.isli.gr'
const Options_Url = `${API_URL}/Option`
const Auth_Url = `${API_URL}/Auth`

export type Option = {
    value: string | null;
    label: string | null;
}

const getAssociations = (): Promise<Array<Option>> => {
    console.log("query")
  return axios
      .get(`${Options_Url}/associations`)
      .then((d: AxiosResponse<Array<Option>>) => d.data)
}
const getSpecialities = (): Promise<Array<Option>> => {
    console.log("query")
    return axios
        .get(`${Options_Url}/specialities`)
        .then((d: AxiosResponse<Array<Option>>) => d.data)
}
const getApplicationTypes = (): Promise<Array<Option>> => {
    console.log("query")
    return axios
        .get(`${Options_Url}/applicationtypes`)
        .then((d: AxiosResponse<Array<Option>>) => d.data)
}
const getCheckCert = (code: string | null, appId: string | null): Promise<string> => {
    console.log("query")
    return axios
        .get(`${Auth_Url}/CheckCertificateEnabled/${code}?appId=${appId}`)
        .then((d: AxiosResponse<string>) => d.data)
}
const getApplicationTypesList = (code: string | null): Promise<Array<Option>> => {
    console.log("query")
    return axios
        .get(`${Options_Url}/applicationtypeswrkplc/${code}`)
        .then((d: AxiosResponse<Array<Option>>) => d.data)
}
const getFiles = (): Promise<Array<Option>> => {
    console.log("query")
    return axios
        .get(`${Options_Url}/files`)
        .then((d: AxiosResponse<Array<Option>>) => d.data)
}
const getInternships = (): Promise<Array<Option>> => {
    console.log("query")
    return axios
        .get(`${Options_Url}/internships`)
        .then((d: AxiosResponse<Array<Option>>) => d.data)
}

const getHospitals = (code: string): Promise<Array<Option>> => {
    console.log("query")
    return axios
        .get(`${Options_Url}/hospitals/${code}`)
        .then((d: AxiosResponse<Array<Option>>) => d.data)
}
const getNationalities = (): Promise<Array<Option>> => {
    console.log("query")
    return axios
        .get(`${Options_Url}/natinalities`)
        .then((d: AxiosResponse<Array<Option>>) => d.data)
}

//const getCertificates = (query: string): Promise<CertificateResponse> => {
//}



export { getCheckCert, getAssociations, getFiles, getNationalities, getSpecialities, getHospitals, getInternships, getApplicationTypes, getApplicationTypesList}
