import { createContext, useEffect, useState, FC } from 'react'
import { UserPreviewModalHeader } from './UserPreviewModalHeader'
import { Document, Page   } from 'react-pdf';
import { url } from 'node:inspector';
import { useListView } from '../core/ListViewProvider'

type Props = {
    id: number | null
}
const UserPreviewModal: FC<Props> = ({ id }) => {
    useEffect(() => {
        handleFileFetch();
        document.body.classList.add('modal-open')
        return () => {
            document.body.classList.remove('modal-open')
        }
    }, [])

    const handleFileFetch = async () => {
        const response = await fetch(`https://e-services.isli.gr/Certificate/download/` + id);
        if (response.ok) {
           
            const textData = await response.text();
          
            setPdfBlob(textData);
        } else {
            console.error('Failed to fetch file:', response.statusText);
        }
    };
    const [fileLocation, setfileLocation] = useState<string | null>(null);
    const [pdfBlob, setPdfBlob] = useState < string | null>(null);
    return (
        <>

            <div
                className='modal fade show d-block '
                id='kt_modal_add_user'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                {/* begin::Modal dialog */}
                <div className='modal-dialog modal-dialog-centered mw-800px '>
                    {/* begin::Modal content */}
                    <div className='modal-content'>
                        <UserPreviewModalHeader />
                        <embed src={`data:application/pdf;base64,${pdfBlob}`} height='400'/>
                        {/* end::Modal body */}
                    </div>
                    {/* end::Modal content */}
                </div>
                {/* end::Modal dialog */}
            </div>
            {/* begin::Modal Backdrop */}
            <div className='modal-backdrop fade show'></div>
            {/* end::Modal Backdrop */}
        </>
    )
}


export { UserPreviewModal }
