import { FC, lazy } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { CheckCertificate } from '../modules/apps/user-management/CheckCertificate'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'

const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
    const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
    const { currentUser } = useAuth()

    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes>
                <Route element={<App />}>
                    <Route path='error/*' element={<ErrorsPage />} />
                    <Route path='logout' element={<Logout />} />
                    <Route path='checkcertificate' element={<CheckCertificate />} />
                    {currentUser ? (
                        <>
                            <Route path='/*' element={<PrivateRoutes />} />
                            <Route index element={<Navigate to='/crafted/pages/profile/overview' />} />
                            {/* Default route for authenticated users */}
                            <Route path='*' element={<Navigate to='/crafted/pages/profile/overview' />} />
                        </>
                    ) : (
                        <>
                            <Route path='auth/*' element={<AuthPage />} />
                            {/* Redirect root path to /auth if unauthenticated */}
                            <Route index element={<Navigate to='/auth' />} />
                            {/* Default route for unauthenticated users */}
                            <Route path='*' element={<Navigate to='/auth' />} />
                        </>
                    )}
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export { AppRoutes }
