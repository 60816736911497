import { KTIcon } from '../../../../../../_metronic/helpers'
import { useListView } from '../core/ListViewProvider'

const UserPreviewModalHeader = () => {
    const { setItemIdForUpdate, setPreview } = useListView()

    return (
        <div className='modal-header'>
            {/* begin::Modal title */}
            <h2 className='fw-bolder'>Προεπισκόπηση Αρχείου</h2>
            {/* end::Modal title */}

            {/* begin::Close */}
            <div
                className='btn btn-icon btn-sm btn-active-icon-primary'
                data-kt-users-modal-action='close'
                onClick={() => {setItemIdForUpdate(undefined); setPreview(false);}}
                style={{ cursor: 'pointer' }}
            >
                <KTIcon iconName='cross' className='fs-1' />
            </div>
            {/* end::Close */}
        </div>
    )
}

export { UserPreviewModalHeader }
