// @ts-nocheck
import { Column } from 'react-table'
import { UserInfoCell } from './UserInfoCell'
import { UserLastLoginCell } from './UserLastLoginCell'
import { UserTwoStepsCell } from './UserTwoStepsCell'
import { UserActionsCell } from './UserActionsCell'
import { UserSelectionCell } from './UserSelectionCell'
import { UserCustomHeader } from './UserCustomHeader'
import { UserSelectionHeader } from './UserSelectionHeader'
import { Certificate } from '../../core/_models'
import { format } from 'date-fns';

const usersColumns: ReadonlyArray<Column<Certificate>> = [

    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title='Τύπος' className='min-w-125px' />
        ),
        accessor: 'appId',
        Cell: ({ value, row }) => {
            const appId = row.original.appId;

            // Conditionally render content based on the value of appId
            const content = appId === 0 ? 'Αίτηση' : 'Αυτόματο';

            return <span>{content}</span>;
        },
    },
    {
        Header: (props) => <UserCustomHeader tableProps={props} title='Περιγραφη' className='min-w-125px' />,
        accessor: 'description',
    },
    {
        Header: (props) => <UserCustomHeader tableProps={props} title='Σχόλια ΙΣΛ' className='min-w-125px' />,
        accessor: 'notes',
    },
    {
        Header: (props) => <UserCustomHeader tableProps={props} title='Κατάσταση' className='min-w-125px' />,
        accessor: 'cancel',
    },
    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title='Ημ/νια Αιτησης' className='min-w-125px' /> 
        ),
        accessor: 'printDate',
        Cell: ({ value }) => (
            <span>{value ? format(new Date(value), 'dd-MM-yyyy') : ''}</span>
        ),
    },


    {
        Header: (props) => (
            <UserCustomHeader tableProps={props} title='Ενεργειες' className='text-end min-w-100px' />
        ),
        id: 'actions',
        Cell: ({ ...props }) => {
            const appId = props.data[props.row.index].appId;
            const certifId = props.data[props.row.index].certifId;
            console.log("cert" + certifId)

            // Render UserActionsCell only if certifId is not null
            return appId !== 0 ? <UserActionsCell certifId={certifId} /> : null;
        },
    },
]

export { usersColumns }
